.signUpFormContainer {
    padding: 1em;
    box-shadow: 1px 2px 4px -2px black;
    height: 100%;
}

.signInButton {
    margin-top: 0.5em;
    width: 100%;
    font-weight: 500;
    padding: 0.7em;
}

.title {
    font-weight: 650;
    color: #6d6d6d;
}

.link {
    text-decoration: none;
}

.subtext {
    font-weight: 300;
}

.formLabel {
    text-align: left;
    margin-bottom: 0;
    font-size: 12px;
}

.signInBody {
    height: 100vh;
    display: flex;
    padding: var(--Components-Input-Component-paddingBlockSM, 0px);
    align-items: flex-start;
    gap: var(--Components-Input-Component-paddingBlockSM, 0px);
    flex: 1 0 0;
    align-self: stretch;
    border-radius: var(--Components-Input-Component-paddingBlockSM, 0px);
    overflow: hidden;
}

.signInLeftCol {
    display: flex;
    padding: var(--Space-Padding-paddingLG, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--Space-Margin-marginXL, 32px);
    align-self: stretch;
    border-radius: var(--Components-Input-Component-paddingBlockSM, 0px);
}

.signInRightCol {
    position: relative;
    flex: 1;
    align-self: stretch;
    flex-grow: 1;
    z-index: 0;
    display: flex;
}

.signInRightColOverlap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.signInRightColPromo {
    display: flex;
    gap: var(--Space-Margin-marginXXL, 48px);
    /* position: absolute; */
    align-self: stretch;
    margin-left: 100px;
    width: 485px;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    color: var(--Colors-Neutral-colorWhite, #fff);
    z-index: 1;
}

.signInSpace {
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    left: 200px;
    top: 100px;
    gap: var(--Space-Margin-margin, 16px);
    z-index: 0;
}

.signInSpaceDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 282.585px;
}
